import { useEffect, useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material"
import { isAnyBlank } from "../../utils/string"
import AsignaturaConFiltroSelector from "../../components/form/AsignaturaConFiltroSelector/AsignaturaConFiltroSelector"
import css from "./ModalCrearProfesor.module.css"

const ModalCrearProfesor = ({ open, onClose, onSubmit }) => {
  const [nuevoProfesor, setNuevoProfesor] = useState({
    email: "",
    nombre: "",
    asignaturas: [],
  })

  const handleChange = (event) => {
    setNuevoProfesor((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }))
  }

  const handleAsignaturasChange = (_event, value) => {
    setNuevoProfesor((state) => ({
      ...state,
      asignaturas: value.map((a) => a.id),
    }))
  }

  const isSubmitEnabled = !isAnyBlank(
    nuevoProfesor.email,
    nuevoProfesor.nombre,
    nuevoProfesor.asignaturas
  )

  useEffect(() => {
    if (open) {
      setNuevoProfesor({
        email: "",
        nombre: "",
        asignaturas: [],
      })
    }
  }, [open])

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Nuevo profesor</DialogTitle>
      <DialogContent className={css.main}>
        <TextField
          className={css.input}
          fullWidth
          name="email"
          label="Email"
          variant="standard"
          value={nuevoProfesor.email}
          onChange={handleChange}
        />
        <TextField
          className={css.input}
          fullWidth
          name="nombre"
          label="Nombre"
          variant="standard"
          value={nuevoProfesor.nombre}
          onChange={handleChange}
        />
        <AsignaturaConFiltroSelector
          className={css.input}
          fullWidth
          name="asignaturas"
          label="Asignaturas"
          variant="standard"
          value={nuevoProfesor.asignaturas}
          multiple
          onChange={handleAsignaturasChange}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color="primary"
          disabled={!isSubmitEnabled}
          onClick={() => onSubmit(nuevoProfesor)}
        >
          Crear profesor
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalCrearProfesor
