import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useQuery } from "@tanstack/react-query"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import { getAsignaturas } from '../../../utils/api/asignaturas'
import css from './AsignaturaSelector.module.css'

const getSelectedValue = ({asignaturas, value, multiple })=> {
  if (multiple) {
    return asignaturas.filter(asignatura => (value || []).includes(asignatura.id))
  } else {
    return asignaturas.find(asignatura => asignatura.id === value) || null
  }
}

const AsignaturaSelector = ({ 
  className, 
  multiple=false, 
  name, 
  label, 
  value, 
  onChange 
})=> {

  const notification = useNotification()

  const { isLoading, data: asignaturaList=[] } = useQuery({
    queryKey: ['asignatura', 'list' , 'selector'], 
    queryFn: ()=> getAsignaturas()
      .then(datos=> datos
        .map(({ id, nombre, nivel, subnivel })=> {
          if (subnivel) return { id, nombre: `${nombre} (${nivel} - ${subnivel})` }
          return { id, nombre: `${nombre} (${nivel})` }
        })
        .sort((a1, a2)=> a1.nombre.localeCompare(a2.nombre))
      )
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las asignaturas', content: err })
        return []
      })
  })

  const selectedValue = getSelectedValue({ asignaturas: asignaturaList, value, multiple })

  return (
    <Autocomplete
      id={name} 
      className={`${className} ${css.main}`}
      multiple={multiple}
      options={asignaturaList}
      getOptionLabel={option=> option.nombre || ''}
      loading={isLoading}
      loadingText='Cargando asignaturas...'
      noOptionsText='No hay asignaturas'
      value={selectedValue}
      isOptionEqualToValue={(option, value)=> option.id === value.id}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="standard"
        />
      )}
    />
  )
}

export default AsignaturaSelector