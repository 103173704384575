
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { actualizarEvento, borrarEvento, crearEvento, getEventos } from '../../../utils/api/eventos'
import { dateFromString, dateToMonth, dateToString, getStartOfMonth, isSameMonth, monthToString } from '../../../utils/date'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import Calendar from '../../../components/Calendar/Calendar'
import { Fab, IconButton } from '@mui/material'
import ModalEventoCalendario from '../../../modals/ModalEventoCalendario/ModalEventoCalendario'
import ModalNuevoEventoCalendario from '../../../modals/ModalNuevoEventoCalendario/ModalNuevoEventoCalendario'
import css from './Calendario.module.css'

const EventoCalendario = ({ evento, onClick, dimmed })=> {
  
  const handleClick = () => {
    if (!dimmed && onClick) onClick(evento)
  }
  
  return (
    <div 
      className={css.evento} 
      data-status={dimmed ? 'dimmed' : 'normal'}
      data-type={evento.tipo}
      onClick={handleClick}
    >
      <i className='material-icons'>
        {evento.tipo === 'COMUNICADO' && 'campaign'}
        {evento.tipo === 'AVISO' && 'info'}
        {evento.tipo === 'NOTIFICACION' && 'notifications'}
        {evento.tipo === 'RECORDATORIO' && 'alarm'}
        {evento.tipo === 'ALERTA' && 'warning'}
      </i>
      <p className={css.eventoTitulo}>
        {evento.titulo}
      </p>
    </div>
  )
}

const Calendario = ()=> {

  const notification = useNotification()
  const queryClient = useQueryClient()

  const [mes, setMes] = useState(getStartOfMonth())

  const [openCreate, setOpenCreate] = useState(false)

  const [eventoSeleccionado, setEventoSeleccionado] = useState(null)

  const handleMonthChange = (offset) => {
    const newMes = new Date(mes)
    newMes.setMonth(newMes.getMonth() + offset)
    setMes(newMes)
  }

  const { data: eventos=[] } = useQuery({
    queryKey: ['interno', 'eventos-calendario', 'list', monthToString(mes)], 
    queryFn: ()=> getEventos({
      mes: monthToString(mes),
    })
      .then(datos=> datos.map(evento=> ({
        ...evento,
        fecha: dateToString(evento.fecha)
      })))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los eventos', content: err })
        return []
      })
  })

  const { isUpdating: isEventoUpdating, mutate: updateEvento } = useMutation({
    mutationFn: actualizarEvento,
    onSuccess: () => {
      notification.success({ title: 'Evento actualizado', content: 'Evento modificado correctamente' })
      queryClient.invalidateQueries(['interno', 'eventos-calendario', 'list', monthToString(mes)])
      setEventoSeleccionado(null)
    },
    onError: err => {
      notification.error({ title: 'Error actualizando el evento', content: err })
    },
  })

  const { isUpdating: isEventoCreating, mutate: createEvento } = useMutation({
    mutationFn: crearEvento,
    onSuccess: () => {
      notification.success({ title: 'Evento creado', content: 'Evento añadido correctamente' })
      queryClient.invalidateQueries(['interno', 'eventos-calendario', 'list', monthToString(mes)])
      setOpenCreate(false)
    },
    onError: err => {
      notification.error({ title: 'Error creando el evento', content: err })
    },
  })

  const { isUpdating: isEventoDeleting, mutate: deleteEvento } = useMutation({
    mutationFn: borrarEvento,
    onSuccess: () => {
      notification.success({ title: 'Evento borrado', content: 'Evento eliminado correctamente' })
      queryClient.invalidateQueries(['interno', 'eventos-calendario', 'list', monthToString(mes)])
      setEventoSeleccionado(null)
    },
    onError: err => {
      notification.error({ title: 'Error borrando el evento', content: err })
    },
  })

  const handleCreateEvento = (evento) => {
    if (isEventoCreating) return
    const { centros, ...detalles } = evento
    createEvento({
      ...detalles,
      'centros[]': centros
    })
  }

  const handleUpdateEvento = (evento) => {
    if (isEventoUpdating) return
    const { centros, ...detalles } = evento
    updateEvento({
      ...detalles,
      'centros[]': centros
    })
  }

  const handleDeleteEvento = ()=> { 
    if (isEventoDeleting) return
    deleteEvento(eventoSeleccionado.id)
  }
  
  const eventosCalendario = Object.entries(eventos
    .map(evento=> ({
      fecha: evento.fecha,
      evento: evento
    }))
    .reduce((acc, { fecha, evento })=> ({
      ...acc,
      [fecha]: [...(acc[fecha] || []), evento]
    }), {})
  ).reduce((acc, [fecha, eventos])=> ({
    ...acc,
    [fecha]: (
      <>
        {eventos.map((evento)=> (
          <EventoCalendario
            key={evento.id}
            evento={evento}
            dimmed={!isSameMonth(dateFromString(evento.fecha), mes)}
            onClick={setEventoSeleccionado}
          />
        ))}
      </>
    )
  }), {})

  return (
    <div className={css.main}>
      <div className={css.calendar}>
        <div className={css.monthSelection}>
          <IconButton className={css.monthIcon} size='small' onClick={()=> handleMonthChange(-1)}>
            <i className='material-icons'>arrow_back</i>
          </IconButton>
          <p className={css.calendarMonth}>
            {dateToMonth(mes)}
          </p>
          <IconButton className={css.monthIcon} size='small' onClick={()=> handleMonthChange(+1)}>
            <i className='material-icons'>arrow_forward</i>
          </IconButton>
        </div>
        <Calendar
          month={monthToString(mes)}
          placeholder='Sin eventos'
          events={eventosCalendario}
        />
        <Fab
          className={css.createButton}
          color='primary'
          onClick={()=> setOpenCreate(true)}
        >
          <i className='material-icons'>add</i>
        </Fab>
        <ModalNuevoEventoCalendario
          open={openCreate}
          onClose={()=> setOpenCreate(false)}
          onSubmit={handleCreateEvento}
        />
        <ModalEventoCalendario
          open={!!eventoSeleccionado}
          evento={eventoSeleccionado}
          onClose={()=> setEventoSeleccionado(null)}
          onDelete={handleDeleteEvento}
          onSubmit={handleUpdateEvento}
        />
      </div>
    </div>
  )
}

export default Calendario