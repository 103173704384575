import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import { useQuery } from "@tanstack/react-query"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import { getAsignaturas } from "../../../utils/api/asignaturas"
import css from "./AsignaturaConFiltroSelector.module.css"
import { Checkbox, FormControlLabel } from "@mui/material"

const getSelectedValue = ({ asignaturas, value, multiple }) => {
  if (multiple) return asignaturas.filter((asignatura) => (value || []).includes(asignatura.id))
  else return asignaturas.find((asignatura) => asignatura.id === value) || null
}

const AsignaturaConFiltroSelector = ({
  className,
  multiple = false,
  name,
  label,
  value,
  onChange,
}) => {
 
  const notification = useNotification()

  const { isLoading, data: asignaturaList = [] } = useQuery({
    queryKey: ["asignatura", "list", 'selector'],
    queryFn: () => getAsignaturas()
      .then((datos) =>
        datos.map(({ id, nombre, nivel, subnivel, tipo }) => {
          if (subnivel) return { id, nombre: `${nombre} (${nivel} - ${subnivel})`, tipo }
          return { id, nombre: `${nombre} (${nivel})`, tipo }
        })
        .sort((a1, a2) => a1.nombre.localeCompare(a2.nombre))
      )
      .catch((err) => {
        notification.error({ title: "Error al recuperar las asignaturas", content: err })
        return []
      }),
  })

  const asignaturasCiencias = asignaturaList.filter((asignatura) => ['CIENCIAS', 'CIENCIAS SALUD'].includes(asignatura.tipo))
  const asignaturasLetras = asignaturaList.filter((asignatura) => asignatura.tipo === "LETRAS")

  const handleSelectLetras = () => {
    const asignaturasValue = asignaturaList.filter((asignatura) => value.includes(asignatura.id))
    // Si no hay ninguna seleccionada, añadimos todas
    if (!isSomeLetrasChecked) {
      onChange("", [...asignaturasValue, ...asignaturasLetras])
    }
    // Si todas están seleccionadas, las quitamos todas
    else if (isLetrasChecked) {
      onChange("", asignaturasValue.filter((asignatura) => !asignaturasLetras.includes(asignatura)))
    }
    // Si hay alguna seleccionada, añadimos las que faltan
    else {
      const letrasToAdd = asignaturasLetras.filter((asignatura) => !asignaturasValue.includes(asignatura))
      onChange("", [...asignaturasValue, ...letrasToAdd])
    }
  }

  const handleSelectCiencias = () => {
    const asignaturasValue = asignaturaList.filter((asignatura) => value.includes(asignatura.id))
    // Si no hay ninguna seleccionada, añadimos todas
    if (!isSomeCienciasChecked) {
      onChange("", [...asignaturasValue, ...asignaturasCiencias])
    }
    // Si todas están seleccionadas, las quitamos todas
    else if (isCienciasChecked) {
      onChange("", asignaturasValue.filter((asignatura) => !asignaturasCiencias.includes(asignatura)))
    }
    // Si hay alguna seleccionada, añadimos las que faltan
    else {
      const cienciasToAdd = asignaturasCiencias.filter((asignatura) => !asignaturasValue.includes(asignatura))
      onChange("", [...asignaturasValue, ...cienciasToAdd])
    }
  }

  const selectedValue = getSelectedValue({ asignaturas: asignaturaList, value, multiple })

  const isCienciasChecked = asignaturasCiencias.every((asignatura) => selectedValue.includes(asignatura))
  const isLetrasChecked = asignaturasLetras.every((asignatura) => selectedValue.includes(asignatura))
  const isSomeCienciasChecked = asignaturasCiencias.some((asignatura) => selectedValue.includes(asignatura))
  const isSomeLetrasChecked = asignaturasLetras.some((asignatura) => selectedValue.includes(asignatura))

  return (
    <div className={className}>
      <Autocomplete
        id={name}
        className={css.main}
        multiple={multiple}
        options={asignaturaList}
        getOptionLabel={(option) => option.nombre || ""}
        loading={isLoading}
        loadingText="Cargando asignaturas..."
        noOptionsText="No hay asignaturas"
        value={selectedValue}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={onChange}
        renderInput={(params) => (
          <TextField {...params} label={label} variant="standard" />
        )}
      />
      <div className={css.actions}>
        <FormControlLabel
          control={(
            <Checkbox 
              indeterminate={isSomeLetrasChecked && !isLetrasChecked} 
              checked={isLetrasChecked} 
              onChange={handleSelectLetras} 
              name='letras' 
            />
          )}
          label='Asignaturas de letras'
        />
        <FormControlLabel
          control={(
            <Checkbox 
              indeterminate={isSomeCienciasChecked && !isCienciasChecked} 
              checked={isCienciasChecked} 
              onChange={handleSelectCiencias} 
              name='ciencias' 
            />
          )}
          label='Asignaturas de ciencias'
        />
      </div>
    </div>
  )
}

export default AsignaturaConFiltroSelector
