import { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { isBlank } from "../../utils/string"
import CurrencyInput from "../../components/form/CurrencyInput/CurrencyInput"
import AcademiaSelector from "../../components/form/AcademiaSelector/AcademiaSelector"
import FormaPagoSelector from "../../components/form/FormaPagoSelector/FormaPagoSelector"
import FechaSelector from "../../components/form/FechaSelector/FechaSelector"
import HoraSelector from "../../components/form/HoraSelector/HoraSelector"
import { dateToDateTime, dateToFullTimeString, dateToString } from "../../utils/date"
import css from './ModalEditarIngreso.module.css'

const ModalEditarIngreso = ({ ingreso, open, onSubmit, onClose }) => {

  const [ingresoUpdate, setIngresoUpdate] = useState({
    id: '',
    importe: 0,
    concepto: '',
    formaPago: '',
    fecha: null,
    hora: null,
    observaciones: '',
    centro: '',
  })
  
  useEffect(()=> {
    if (open) return
    setIngresoUpdate({
      id: '',
      importe: 0,
      concepto: '',
      formaPago: '',
      fecha: null,
      hora: null,
      observaciones: '',
      centro: '',
    })
  } , [open])

  useEffect(()=> {
    if (!ingreso || !ingreso.id) return
    if (ingresoUpdate.id !== ingreso.id) {
      setIngresoUpdate({
        id: ingreso.id,
        importe: ingreso.importe,
        concepto: ingreso.concepto,
        formaPago: ingreso.formaPago,
        fecha: dateToDateTime(ingreso.fechaIngreso),
        hora: dateToDateTime(ingreso.fechaIngreso),
        observaciones: ingreso.observaciones,
        centro: ingreso.centro,
      })
    }
  }, [ingresoUpdate.id, ingreso])

  const isSubmitEnabled = (
    !isBlank(ingresoUpdate.id) &&
    !isBlank(ingresoUpdate.concepto) &&
    !isBlank(ingresoUpdate.formaPago) &&
    !isBlank(ingresoUpdate.centro)
  )

  const handleSubmit = () => {
    onSubmit({ 
      id: ingresoUpdate.id,
      importe: ingresoUpdate.importe,
      concepto: ingresoUpdate.concepto,
      forma_pago: ingresoUpdate.formaPago,
      observaciones: ingresoUpdate.observaciones,
      centro: ingresoUpdate.centro,
      fecha: `${dateToString(ingresoUpdate.fecha)} ${dateToFullTimeString(ingresoUpdate.hora)}`,
    })
  }

  const isTransferencia = ingresoUpdate.formaPago === 'TRANSFERENCIA'

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Editar ingreso</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Puedes modificar los datos de la ingreso con el siguiente formulario. 
          <br />
          Solamente se puede modificar la fecha del ingreso en el caso de las <strong>transferencias</strong>.
        </DialogContentText>
        <CurrencyInput
          className={css.input}
          name='importe'
          label='Importe'
          value={ingresoUpdate.importe}
          onChange={value=> setIngresoUpdate({ ...ingresoUpdate, importe: value })}
        />
        <TextField
          className={css.input}
          label="Concepto"
          value={ingresoUpdate.concepto}
          onChange={e=> setIngresoUpdate({ ...ingresoUpdate, concepto: e.target.value })}
          variant='standard'
        />
        <FormaPagoSelector
          className={css.input}
          name='forma-pago'
          label='Forma de pago'
          value={ingresoUpdate.formaPago}
          onChange={e=> setIngresoUpdate({ ...ingresoUpdate, formaPago: e.target.value })}
        />
        {isTransferencia &&  
          <>
            <FechaSelector
              className={css.input}
              name='Fecha'
              label='Fecha'
              value={ingresoUpdate.fecha}
              onChange={value => setIngresoUpdate({ ...ingresoUpdate, fecha: value })} 
            />
            <HoraSelector
              className={css.input} 
              name='Hora'
              label='Hora'
              value={ingresoUpdate.hora} 
              onChange={value=> setIngresoUpdate({ ...ingresoUpdate, hora: value })}
            />
          </>
        }
        <AcademiaSelector
          className={css.input}
          name='academia'
          label='Academia'
          value={ingresoUpdate.centro}
          onChange={e=> setIngresoUpdate({ ...ingresoUpdate, centro: e.target.value })}
        />
        <TextField
          className={css.input}
          label="Observaciones"
          variant='standard'
          value={ingresoUpdate.observaciones}
          onChange={e=> setIngresoUpdate({ ...ingresoUpdate, observaciones: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>Cancelar</Button>
        <Button disabled={!isSubmitEnabled} color='primary' onClick={handleSubmit}>Actualizar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarIngreso