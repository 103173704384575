import { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material"
import AcademiaSelector from "../../components/form/AcademiaSelector/AcademiaSelector"
import RolSelector from "../../components/form/RolSelector/RolSelector"
import { isAnyBlank } from "../../utils/string"
import css from "./ModalCrearUsuario.module.css"

const ModalCrearUsuario = ({ open, onClose, onSubmit }) => {

  const [usuario, setUsuario] = useState({
    email: '',
    nombre: '',
    rol: '',
    centros: [],
    centro: '',
  })

  const handleChange = (event) => {
    setUsuario((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }))
  }

  useEffect(()=>{
    if (!open) return
    setUsuario({
      email: '',
      nombre: '',
      rol: '',
      centros: [],
      centro: '',
    })
  },[open])

  const accesoIncorrecto = usuario.centro && !usuario.centros.includes(usuario.centro)
  const isSubmitEnabled = !isAnyBlank(usuario.email, usuario.nombre, usuario.rol, usuario.centro) && !accesoIncorrecto
  
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Nuevo usuario</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Vas a añadir un nuevo usuario a ágora.
        </DialogContentText>
        {accesoIncorrecto && (
          <Alert className={css.warningBanner} severity="warning">
            Es necesario que el centro de trabajo esté incluido en los centros con acceso.
          </Alert>
        )}
        <TextField
          className={css.input}
          fullWidth
          name="email"
          label="Email"
          variant="standard"
          value={usuario.email}
          onChange={handleChange}
        />
        <TextField
          className={css.input}
          fullWidth
          name="nombre"
          label="Nombre"
          variant="standard"
          value={usuario.nombre}
          onChange={handleChange}
        />
        <RolSelector
          className={css.input}
          fullWidth
          name="rol"
          label="Rol"
          value={usuario.rol}
          onChange={handleChange}
        />
        <AcademiaSelector
          className={css.input}
          fullWidth
          name="centro"
          label="Centro de trabajo"
          value={usuario.centro}
          onChange={handleChange}
        />
        <AcademiaSelector
          className={css.input}
          fullWidth
          name="centros"
          label="Centros con acceso"
          multiple
          value={usuario.centros}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color="primary"
          disabled={!isSubmitEnabled} 
          onClick={()=> onSubmit(usuario)}
        >
          Crear usuario
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalCrearUsuario
