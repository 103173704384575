import { useEffect, useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material"
import AcademiaSelector from "../../components/form/AcademiaSelector/AcademiaSelector"
import { isAnyBlank } from "../../utils/string"
import css from "./ModalCrearFormacion.module.css"

const ModalCrearFormacion = ({ open, onClose, onSubmit }) => {

  const [formacion, setFormacion] = useState({
    nombre:'',
    email: '',
    centro: '',
  })

  const handleChange = (event) => {
    setFormacion((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }))
  }

  useEffect(()=>{
    if (!open) return
    setFormacion({
      nombre:'',
      email: '',
      centro: '',
    })
  },[open])

  const isSubmitEnabled = !isAnyBlank(formacion.nombre,formacion.email, formacion.centro)
  
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Nueva formación</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Vas a añadir una nueva formación en ágora. Comprueba que el email es correcto.
        </DialogContentText>
        <TextField
          className={css.input}
          fullWidth
          name="nombre"
          label="Nombre"
          value={formacion.nombre}
          variant='standard'
          onChange={handleChange}
        />
        <TextField
          className={css.input}
          fullWidth
          name="email"
          label="Email"
          variant="standard"
          value={formacion.email}
          onChange={handleChange}
        />
        <AcademiaSelector
          className={css.input}
          fullWidth
          name="centro"
          label="Centro"
          value={formacion.centro}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color="primary"
          disabled={!isSubmitEnabled} 
          onClick={()=> onSubmit(formacion)}
        >
          Crear formación
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalCrearFormacion
