import { Fab, IconButton, Typography } from '@mui/material'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import PageLoading from '../../../components/PageLoading/PageLoading'
import InformationTable from '../../../components/Table/InformationTable'
import TableHeader from '../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import { createUsuario, editUsuario, getUsuarioList } from '../../../utils/api/usuarios'
import { downloadCSVFile, getCSVContent } from '../../../utils/csv'
import { textFilter } from '../../../utils/table'
import { dateToFullString } from '../../../utils/date'
import ModalCrearUsuario from '../../../modals/ModalCrearUsuario/ModalCrearUsuario'
import ModalEditarUsuario from '../../../modals/ModalEditarUsuario/ModalEditarUsuario'
import css from './Administracion.module.css'

const StatusIcon = ({ isOk })=> (
  <i 
    className={`${css.icon} material-icons`}
    data-status={isOk ? 'valid' : 'invalid'}
  >
    {isOk ? 'check' : 'close'}
  </i>
)

const Administracion = ()=> {

  const notification = useNotification()
  const queryClient = useQueryClient()


  const [search, setSearch] = useState('')
  const [isOpenNuevoUsuario, setIsOpenNuevoUsuario] = useState(false)
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState(null)

  const { isLoading, data: adminList=[] } = useQuery({
    queryKey: ['usuario', 'list'], 
    queryFn: ()=> getUsuarioList()
      .then(datos=> datos
        .map(usuario=> ({
          ...usuario,
          ultimaConexion: dateToFullString(usuario.ultimaConexion)
        }))
        .sort((a, b)=> {
          if (a.cuentaBloqueada && !b.cuentaBloqueada) return 1
          if (!a.cuentaBloqueada && b.cuentaBloqueada) return -1
          return a.nombre.localeCompare(b.nombre)
        })
    )
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los usuarios', content: err })
        return []
      })
  })

  const { isPending: isUsuarioCreating, mutate: crearUsuario } = useMutation({
    mutationFn: createUsuario,
    onSuccess: ()=> {
      setIsOpenNuevoUsuario(false)
      queryClient.invalidateQueries(['usuario', 'list'])
      notification.success({ title: 'Usuario creado', content: 'El usuario se ha creado.' })
    },
    onError: (err)=> notification.error({ title: 'Error al crear usuario', content: err })
  })

  const { isPending: isUsuarioUpdating, mutate: updateUsuario } = useMutation({
    mutationFn: editUsuario,
    onSuccess: () => {
      setUsuarioSeleccionado(null)
      queryClient.invalidateQueries(['usuario', 'list'])
      notification.success({ title: 'Usuario actualizado', content: 'Usuario actualizado correctamente' })
    },
    onError: err => notification.error({ title: 'Error actualizando usuario', content: err })
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Nombre', 'Email', 'Rol', 'Estado'],
      data: adminList,
      transform: a=> ([
        a.nombre,
        a.email,
        a.rol,
        a.ultimaConexion,
        a.cuentaBloqueada ? 'Bloqueado' : 'Activo',
      ])
    })
    downloadCSVFile(content, 'usuarios')
  }

  const filterSearch = usuario=> {
    return textFilter({ 
      object: usuario, 
      fields: ['nombre', 'email', 'rol'],
      search: search
    })
  }

  const handleNuevoUsuario = (nuevoUsuario)=> {
    if (isUsuarioCreating) return
    crearUsuario (nuevoUsuario)
  }

  const handleEditarUsuario = (usuarioActualizado) => {
    if (isUsuarioUpdating) return
      updateUsuario(usuarioActualizado)
    
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <IconButton size='small' color='primary' onClick={handleExport}>
            <i className='material-icons'>download</i>
          </IconButton>
        )}
        title='Personal de administración'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        size='small'
        details={[
          { title: 'Nombre', key: 'nombre', align: 'left' },
          { title: 'Email', key: 'email', align: 'left' },
          { title: 'Rol', key: 'rol', align: 'left' },
          { title: 'Última conexión', key: 'conexion', align: 'left'},
          { title: 'Acceso', key: 'acceso', sortDisabled: true, align: 'center' },
        ]}
        data={adminList
          .filter(filterSearch)
          .map(admin=> ({
            nombre: (
              <Typography 
                className={css.name} 
                onClick={() => setUsuarioSeleccionado(admin)}
              >
                {admin.nombre}
              </Typography>
            ),
            email: admin.email,
            rol: admin.rol,
            conexion: admin.ultimaConexion || 'Anterior a 2024',
            acceso: <StatusIcon isOk={!admin.cuentaBloqueada} />,
          }))
        }
      />
      <Fab
        className={css.nuevo}
        disabled={isUsuarioCreating}
        onClick={() => setIsOpenNuevoUsuario(true)}
        size='medium'
        color='primary'
      >
        <i className='material-icons'>add</i>
      </Fab>
      <ModalCrearUsuario
        open={isOpenNuevoUsuario}
        onClose={()=> setIsOpenNuevoUsuario(false)}
        onSubmit={handleNuevoUsuario}
      />
      <ModalEditarUsuario
        usuario={usuarioSeleccionado} 
        open={!!usuarioSeleccionado}
        onClose={()=> setUsuarioSeleccionado(null)}
        onSubmit={handleEditarUsuario}
      />
    </PageLoading>
  )
}

export default Administracion