import { Fab, IconButton, Typography } from '@mui/material'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import PageLoading from '../../../components/PageLoading/PageLoading'
import InformationTable from '../../../components/Table/InformationTable'
import TableHeader from '../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import { createProfesor, editProfesor, getProfesorList } from '../../../utils/api/profesores'
import { downloadCSVFile, getCSVContent } from '../../../utils/csv'
import { textFilter } from '../../../utils/table'
import ModalCrearProfesor from '../../../modals/ModalCrearProfesor/ModalCrearProfesor'
import ModalEditarProfesor from '../../../modals/ModalEditarProfesor/ModalEditarProfesor'
import css from './Profesorado.module.css'

const StatusIcon = ({ isOk })=> (
  <i 
    className={`${css.icon} material-icons`}
    data-status={isOk ? 'valid' : 'invalid'}
  >
    {isOk ? 'check' : 'close'}
  </i>
)

const Profesorado = ()=> {

  const notification = useNotification()
  const queryClient = useQueryClient()

  const [search, setSearch] = useState('')
  const [isOpenNuevoProfesor, setIsOpenNuevoProfesor] = useState(false)
  const [profesorSeleccionado, setProfesorSeleccionado] = useState(null)

  const { isLoading, data: profesorList=[] } = useQuery({
    queryKey: ['profesor', 'list'], 
    queryFn: ()=> getProfesorList()
      .then(datos=> datos.sort((a, b)=> {
        if (a.cuentaBloqueada && !b.cuentaBloqueada) return 1
        if (!a.cuentaBloqueada && b.cuentaBloqueada) return -1
        return a.nombre.localeCompare(b.nombre)
      }))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los profesores', content: err })
        return []
      })
  })

  const { isPending: isProfesorCreating, mutate: crearProfesor } = useMutation({
    mutationFn: createProfesor,
    onSuccess: ()=> {
      setIsOpenNuevoProfesor(false)
      queryClient.invalidateQueries({ queryKey: ['profesor', 'list'] })
      notification.success({ title: 'Profesor creado',  content: 'Profe creado correctamente' })
    },
    onError: (err)=> notification.error({ title: 'Error al crear profesor', content: err })
  })

  const { isPending: isProfesorUpdating, mutate: updateProfesor } = useMutation({
    mutationFn: editProfesor,
    onSuccess: () => {
      setProfesorSeleccionado(null)
      queryClient.invalidateQueries({ queryKey: ['profesor', 'list'] })
      notification.success({ title: 'Profesor actualizado', content: 'Profesor actualizado correctamente' })
    },
    onError: err => notification.error({ title: 'Error actualizando profesor', content: err })
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Nombre', 'Email', 'Estado'],
      data: profesorList,
      transform: a=> ([
        a.nombre,
        a.email,
        a.rol,
        a.cuentaBloqueada ? 'Bloqueado' : 'Activo',
      ])
    })
    downloadCSVFile(content, 'usuarios')
  }

  const filterSearch = usuario=> {
    return textFilter({ 
      object: usuario, 
      fields: ['nombre', 'email'],
      search: search
    })
  }

  const handleNuevoProfesor = (nuevoProfesor)=> {
    if (isProfesorCreating) return
    crearProfesor(nuevoProfesor)
  }

  const handleEditarProfesor = (profesorActualizado) => {
    if (isProfesorUpdating) return
    updateProfesor(profesorActualizado)
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <IconButton size='small' color='primary' onClick={handleExport}>
            <i className='material-icons'>download</i>
          </IconButton>
        )}
        title='Profesores'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        size='small'
        details={[
          { title: 'Nombre', key: 'nombre', align: 'left' },
          { title: 'Email', key: 'email', align: 'left' },
          { title: 'Acceso', key: 'acceso', sortDisabled: true, align: 'center' },
        ]}
        data={profesorList
          .filter(filterSearch)
          .map(profesor=> ({
            nombre:  <Typography className={css.name} onClick={() => setProfesorSeleccionado(profesor)}
            >{profesor.nombre}</Typography>,
            email: profesor.email,
            acceso: <StatusIcon isOk={!profesor.cuentaBloqueada} />,
          }))
        }
      />
       <Fab
          className={css.nuevo}
          disabled={isProfesorCreating}
          onClick={() => setIsOpenNuevoProfesor(true)}
          color='primary'
        >
          <i className='material-icons'>add</i>
        </Fab>
        <ModalCrearProfesor
          open={isOpenNuevoProfesor}
          onClose={()=> setIsOpenNuevoProfesor(false)}
          onSubmit={handleNuevoProfesor}
        />
        <ModalEditarProfesor
          profesor={profesorSeleccionado} 
          open={!!profesorSeleccionado}
          onClose={()=> setProfesorSeleccionado(null)}
          onSubmit={handleEditarProfesor}
        />
    </PageLoading>
  )
}

export default Profesorado