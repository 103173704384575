import { useEffect, useState } from "react"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material"
import AsignaturaConFiltroSelector from "../../components/form/AsignaturaConFiltroSelector/AsignaturaConFiltroSelector"
import css from "./ModalEditarProfesor.module.css"

const ModalEditarProfesor = ({ profesor, open, onClose, onSubmit }) => {

  const [profesorActualizado, setProfesorActualizado] = useState({
    email: "",
    nombre: "",
    acceso: false,
    asignaturas: [],
  })

  useEffect(() => {
    if (!profesor) return
    setProfesorActualizado({
      id: profesor.id || "",
      email: profesor.email || "",
      nombre: profesor.nombre || "",
      acceso: profesor.cuentaBloqueada,
      asignaturas: profesor.asignaturas.map(asignatura => asignatura.id),
    })
  }, [profesor, open])



  const handleNombreChange = (event) => {
    setProfesorActualizado((state) => ({
      ...state,
      nombre: event.target.value,
    }))
  }

  const handleAsignaturasChange = (_event, value) => {
    setProfesorActualizado((state) => ({
      ...state,
      asignaturas: value.map((a) => a.id),
    }))
  }

  const handleAccesoChange = (event) => {
    setProfesorActualizado((state) => ({
      ...state,
      acceso: event.target.checked,
    }))
  }

  const handleSubmit = () => {
    const profesorFinal = {
      id: profesorActualizado.id,
      email: profesorActualizado.email,
      nombre: profesorActualizado.nombre,
      acceso: profesorActualizado.acceso,
      asignaturas: profesorActualizado.asignaturas,
    }
    onSubmit(profesorFinal)
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Editar profesor</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Vas a editar los datos de un profesor. 
          Puedes modificar las asignaturas que imparte, así como su acceso a Ágora.
        </DialogContentText>
        <TextField
          className={css.input}
          fullWidth
          label="Email"
          variant="standard"
          value={profesorActualizado.email}
          disabled
        />
        <TextField
          className={css.input}
          fullWidth
          name="nombre"
          label="Nombre"
          variant="standard"
          value={profesorActualizado.nombre}
          onChange={handleNombreChange}
        />
        <AsignaturaConFiltroSelector
          className={css.input}
          fullWidth
          name="asignaturas"
          label="Asignaturas"
          variant="standard"
          value={profesorActualizado.asignaturas}
          multiple
          onChange={handleAsignaturasChange}
        />
        <FormControlLabel
          className={css.input}
          control={
            <Checkbox
              checked={!profesorActualizado.acceso}
              onChange={handleAccesoChange}
            />
          }
          label="Acceso a Ágora"
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Cancelar
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          Actualizar profesor
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarProfesor
