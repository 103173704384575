import { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material"
import AcademiaSelector from "../../components/form/AcademiaSelector/AcademiaSelector"
import RolSelector from "../../components/form/RolSelector/RolSelector"
import { isAnyBlank } from "../../utils/string"
import css from "./ModalEditarUsuario.module.css"

const ModalEditarUsuario = ({ usuario, open, onClose, onSubmit }) => {

  const [usuarioActualizado, setUsuarioActualizado] = useState({
    id: '',
    email: '',
    nombre: '',
    rol: '',
    centro: '',
    centros: [],
    acceso: false,
  })

  const handleChange = (event) => {
    setUsuarioActualizado((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }))
  }

  const handleAccessChange = (event) => {
    setUsuarioActualizado({ 
      ...usuarioActualizado, 
      acceso: event.target.checked 
    })
  }

  const handleSubmit = () => {
    onSubmit(usuarioActualizado)
  }

  useEffect(() => {
    if (!usuario) return
    setUsuarioActualizado({
      id: usuario.id || '', 
      email: usuario.email || '',
      nombre: usuario.nombre || '', 
      rol: usuario.rol || '',
      centro: usuario.centro || '',
      centros: usuario.centrosPermitidos || [], 
      acceso: !usuario.cuentaBloqueada
    })
  }, [usuario, open])

  const accesoIncorrecto = usuarioActualizado.centro && !usuarioActualizado.centros.includes(usuarioActualizado.centro)
  const isSubmitEnabled = !isAnyBlank(usuarioActualizado.email, usuarioActualizado.nombre, usuarioActualizado.rol, usuarioActualizado.centro) && !accesoIncorrecto
  
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Editar usuario</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Vas a editar los datos de acceso de {usuarioActualizado.nombre}.
        </DialogContentText>
        {accesoIncorrecto && (
          <Alert className={css.warningBanner} severity="warning">
            Es necesario que el centro de trabajo esté incluido en los centros con acceso.
          </Alert>
        )}
        <TextField
          className={css.input}
          fullWidth
          label="Email"
          variant="standard"
          value={usuarioActualizado.email}
          disabled
        />
        <TextField
          className={css.input}
          fullWidth
          name="nombre"
          label="Nombre"
          variant="standard"
          value={usuarioActualizado.nombre}
          onChange={handleChange}
        />
        <RolSelector
          className={css.input}
          fullWidth
          name="rol"
          label="Rol"
          value={usuarioActualizado.rol}
          onChange={handleChange}
        />
        <AcademiaSelector
          className={css.input}
          fullWidth
          disabled
          name="centro"
          label="Centro de trabajo"
          value={usuarioActualizado.centro}
          onChange={handleChange}
        />
        <AcademiaSelector
          className={css.input}
          fullWidth
          name="centros"
          label="Centros con acceso"
          multiple
          value={usuarioActualizado.centros}
          onChange={handleChange}
        />
        <FormControlLabel
          control={
            <Checkbox 
              checked={usuarioActualizado.acceso} 
              onChange={handleAccessChange} 
            />
          }
          label='Acceso a mundoestudiante'
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Cancelar
        </Button>
        <Button color="primary" disabled={!isSubmitEnabled} onClick={handleSubmit}>
          Actualizar usuario
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarUsuario
